/* You can add global styles to this file, and also import other style files */
.bg-text {
  text-shadow: 2px 2px 2px #673AB7;
  color: #80c1ff;
  font-size: x-large;
  font-weight: bold;
}

.bg-text-color-1 {
  color: #ffffff;
  font-size: medium;
  font-weight: bold;
}

.bg-text-color-2 {
  color: #80c1ff;
  font-size: medium;
  font-weight: bold;
}

.responsive-image {
  width: 100%;
  height: auto;
}

.with-bg {
  background-image: url('./assets/images/bg-page.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.img-loading {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.paragraph-text {
  text-align: justify;
  text-justify: inter-word;
}
